import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";

import {
  DESKTOP_1024,
  TABLET_992,
  TABLET_800,
  TABLET_768,
  TABLET_740,
  MOBILE_460,
} from "../../styles/globals/sizes";

export const PhoneIcon = styled.img`
  align-self: center;
  margin: 6px 25px 0;
  @media (max-width: ${TABLET_992}px) {
    margin: 0;
    padding-right: 20px;
    align-self: center;
    position: relative;
    left: -20px;
  }
  @media (max-width: ${TABLET_768}px) {
    text-align: center;
  }
  @media (max-width: ${TABLET_740}px) {
    margin: 20px 0 10px;
    padding: 0;
    position: static;
  }
`;

export const GetInTouchContainer = styled.div`
  background-color: ${COLORS.vermilion};
  display: flex;
  flex-direction: row;
  padding: 70px 0;
  background-size: cover;
  align-items: center;
  flex: 1;
  justify-content: space-between;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
  }
`;

export const GetInTouchext = styled.div`
  font: 400 36px/40px Roboto;
  color: ${COLORS.white};
  display: flex;
  flex-direction: column;

  @media (max-width: ${TABLET_992}px) {
    position: relative;
    left: -20px;
  }
  @media (max-width: ${TABLET_768}px) {
    text-align: center;
  }
  @media (max-width: ${TABLET_740}px) {
    position: static;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 400 30px/35px Roboto;
  }
`;

export const SmallText = styled.div`
  margin-top: 15px;
  font: 300 20px/24px Roboto;
  color: ${COLORS.white};
  @media (max-width: ${TABLET_992}px) {
    margin: 15px auto 0;
  }
`;

export const GetInTouchDiv = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: ${TABLET_740}px) {
    flex-direction: column-reverse;
  }
`;

export const GetInTouchButton = styled.a`
  padding: 10px 70px;
  align-self: center;
  font: 400 18px/24px Roboto;
  background-color: ${COLORS.white};
  color: ${COLORS.vermilion};
  border-radius: 24px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  display: ${(props) =>  props.mobile ? "none" : "flex"} !important;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    opacity: 0.8;
  }
  
  @media (max-width: ${DESKTOP_1024}px) {
    padding: 10px 60px;
    margin: 0;
  }
  @media (max-width: ${TABLET_800}px) {
    padding: 10px 80px;
    margin: 0 auto;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: ${(props) =>  props.mobile ? "flex" : "none"} !important;
  }
`;
